import React from "react";

import classes from "../../NewBorrowing.module.css";

const BorrowerData = (props) => {

  const isValidInput = () => {
    const isNum = /^\d+$/.test(props.userData.borrower_count);
    return props.userData.phone != null &&
    props.userData.phone !== "" &&
    props.userData.purpose != null &&
    props.userData.purpose !== "" &&
    isNum &&
    Number(props.userData.borrower_count) > 0 && Number(props.userData.borrower_count) <= 40;
  }

  const nextButtonHandler = () => {
    if (isValidInput() === true) {
      props.changeStateHandler(6);
    }
  };

  const backButtonHandler = () => {
    props.changeStateHandler(4);
  };

  let nextButtonClasses = [classes.NextButton];

  if (
    isValidInput() === false || 
    (props.selectedLabType.name == "Alam Sutera - Food Tech Laboratory" &&
      (props.userData.chemicals == null || props.userData.chemicals === ""))
  ) {
    nextButtonClasses.push(classes.ButtonDisabled);
  }

  if (props.selectedLabType.name.includes("Food Tech")) {
    return (
      <div className={classes.BorrowingDataContainer}>
        <div className={classes.Title}>
          Next, we will need information about you...
        </div>
        <div className={classes.SubTitle__Container}>
          <div className={classes.SubTitle}>Phone</div>
          <p className={classes.Description}>
            Please provide your active number, so we can reach you if something
            happen!
          </p>
          <input
            type="tel"
            className={classes.InputText}
            value={props.userData.phone}
            placeholder="Ex: +62821xxxxxxxxx0"
            pattern="[0-9]{12}"
            onChange={(evt) => {
              props.setUserDataHandler("phone", evt.target.value);
            }}
            onKeyPress={(evt) => {
              let reg = new RegExp("\\d");
              if (!reg.test(evt.key)) {
                evt.preventDefault();
              }
            }}
          />
        </div>
        <div className={classes.SubTitle__Container}>
          <div className={classes.SubTitle}>Purpose</div>
          <p className={classes.Description}>
            Tell us briefly about your borrowing request purpose(s)!
          </p>
          <textarea
            className={classes.InputText}
            value={props.userData.purpose}
            placeholder="Ex: Homework for Photography and Design DSGN0000"
            onChange={(evt) => {
              props.setUserDataHandler("purpose", evt.target.value);
            }}
          />
        </div>

        <div className={classes.SubTitle__Container}>
          <div className={classes.SubTitle}>Laboratory Materials Request</div>
          <p className={classes.Description}>
            Select "Yes" if you want to use any laboratory materials (e.g.
            chemicals, media).
          </p>
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              name="chemicals"
              value="Yes"
              onChange={(evt) => {
                props.setUserDataHandler("chemicals", evt.target.value);
              }}
            />
            <div>   
              &nbsp;Yes (You have to submit additional &nbsp;
              <a
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSdlWQoTAF7Sn_wjr67zXpps72AwuZy2tDkk8I1HKPTZ_FsRDg/viewform"
                style={{
                  color: "#0098d7",
                }}
              >
                form
              </a>
              ). Choosing this option <b>WITHOUT</b> submiting the form will result in a <b>REJECTION</b> of the room borrowing. 
            </div>
          </label>
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              name="chemicals"
              value="No"
              onChange={(evt) => {
                props.setUserDataHandler("chemicals", evt.target.value);
              }}
            />
            &nbsp;No
          </label>
        </div>
        <div className={classes.ButtonContainer__SpaceBetween}>
          <div className={classes.BackButton} onClick={backButtonHandler}>
            <i className="material-icons" style={{ marginRight: "8px" }}>
              arrow_back
            </i>
            Back
          </div>
          <div
            className={nextButtonClasses.join(" ")}
            onClick={nextButtonHandler}
          >
            Next
            <i className="material-icons" style={{ marginLeft: "8px" }}>
              arrow_forward
            </i>
          </div>
        </div>
      </div>
    );
  } else if (
    props.selectedLabType.name.includes("Computer") || props.selectedLabType.name.includes("Software") 
  ) {
    return (
      <div className={classes.BorrowingDataContainer}>
        <div className={classes.Title}>
          Next, we will need information about you...
        </div>
        <div className={classes.SubTitle__Container}>
          <div className={classes.SubTitle}>Phone</div>
          <p className={classes.Description}>
            Please provide your active number, so we can reach you if something
            happen!
          </p>
          <input
            type="tel"
            className={classes.InputText}
            value={props.userData.phone}
            placeholder="Ex: +62821xxxxxxxxx0"
            pattern="[0-9]{12}"
            onChange={(evt) => {
              props.setUserDataHandler("phone", evt.target.value);
            }}
            onKeyPress={(evt) => {
              let reg = new RegExp("\\d");
              if (!reg.test(evt.key)) {
                evt.preventDefault();
              }
            }}
          />
        </div>
        <div className={classes.SubTitle__Container}>
          <div className={classes.SubTitle}>Purpose</div>
          <p className={classes.Description}>
            Tell us briefly about your borrowing request purpose(s)!
          </p>
          <textarea
            className={classes.InputText}
            value={props.userData.purpose}
            placeholder="Ex: Homework for Photography and Design DSGN0000"
            onChange={(evt) => {
              props.setUserDataHandler("purpose", evt.target.value);
            }}
          />
        </div>
        {props.selectedLabType.name.includes("Computer") || props.selectedLabType.name.includes("Software")  ? (
          <div className={classes.SubTitle__Container}>
            <div className={classes.SubTitle}>Borrower Count</div>
            <p className={classes.Description}>
              Estimated number of borrower(s)! minmal: 1; maximum: 40
            </p>
            <textarea
              className={classes.InputText}
              value={props.userData.borrower_count}
              placeholder="Ex: 10, minmal: 1; maximum: 40"
              onChange={(evt) => {
                props.setUserDataHandler("borrower_count", evt.target.value);
              }}
            />
          </div>
        ) : null}

        <div className={classes.SubTitle__Container}>
          <div className={classes.SubTitle}>Laboratory Internet Request</div>
          <p className={classes.Description}>
            Select "Yes" if you want to use internet connection.
          </p>
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              name="need_internet"
              value={1}
              onChange={(evt) => {
                props.setUserDataHandler("need_internet", evt.target.value);
              }}
            />
            &nbsp;Yes
          </label>
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              name="need_internet"
              value={0}
              onChange={(evt) => {
                props.setUserDataHandler("need_internet", evt.target.value);
              }}
            />
            &nbsp;No
          </label>
        </div>
        <div className={classes.ButtonContainer__SpaceBetween}>
          <div className={classes.BackButton} onClick={backButtonHandler}>
            <i className="material-icons" style={{ marginRight: "8px" }}>
              arrow_back
            </i>
            Back
          </div>
          <div
            className={nextButtonClasses.join(" ")}
            onClick={nextButtonHandler}
          >
            Next
            <i className="material-icons" style={{ marginLeft: "8px" }}>
              arrow_forward
            </i>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.BorrowingDataContainer}>
      <div className={classes.Title}>
        Next, we will need information about you...
      </div>
      <div className={classes.SubTitle__Container}>
        <div className={classes.SubTitle}>Phone</div>
        <p className={classes.Description}>
          Please provide your active number, so we can reach you if something
          happen!
        </p>
        <input
          type="tel"
          className={classes.InputText}
          value={props.userData.phone}
          placeholder="Ex: +62821xxxxxxxxx0"
          pattern="[0-9]{12}"
          onChange={(evt) => {
            props.setUserDataHandler("phone", evt.target.value);
          }}
          onKeyPress={(evt) => {
            let reg = new RegExp("\\d");
            if (!reg.test(evt.key)) {
              evt.preventDefault();
            }
          }}
        />
      </div>
      <div className={classes.SubTitle__Container}>
        <div className={classes.SubTitle}>Purpose</div>
        <p className={classes.Description}>
          Tell us briefly about your borrowing request purpose(s)!
        </p>
        <textarea
          className={classes.InputText}
          value={props.userData.purpose}
          placeholder="Ex: Homework for Photography and Design DSGN000"
          onChange={(evt) => {
            props.setUserDataHandler("purpose", evt.target.value);
          }}
        />
      </div>
      <div className={classes.ButtonContainer__SpaceBetween}>
        <div className={classes.BackButton} onClick={backButtonHandler}>
          <i className="material-icons" style={{ marginRight: "8px" }}>
            arrow_back
          </i>
          Back
        </div>
        <div
          className={nextButtonClasses.join(" ")}
          onClick={nextButtonHandler}
        >
          Next
          <i className="material-icons" style={{ marginLeft: "8px" }}>
            arrow_forward
          </i>
        </div>
      </div>
    </div>
  );
};

export default BorrowerData;
